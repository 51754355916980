.nav-link {
  position: relative;
  color: #737373; /* nav-grey color */
  text-decoration: none;
  transition: color 0.3s ease;
  display: inline-block; /* To fit the width of the text */
}

.nav-link:hover,
.nav-link.active {
  color: #ffffff; /* primary color */
}

.nav-link .underline {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #ffffff; /* primary color */
  opacity: 0; /* initially hidden */
  transition: opacity 0.3s ease;
}

.nav-link.active .underline {
  opacity: 0.5; /* visible for active link */
}
